<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon icon="EditIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="callOpenSidebar()" />
    </div>
</template>

<script>
    export default {
        name: 'CellRendererActionsServiceSchools',
        methods: {
          callOpenSidebar() {
            const data = this.params.data
            this.params.setSidebarData(data)
            this.params.openSidebar()
          }
        }
    }
</script>
