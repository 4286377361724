<template>

  <div>
    <service-schools-sidebar 
      :isSidebarActive="openDataSidebar" 
      @closeSidebar="toggleDataSidebar"
      :data="sidebarData"
      @showNotify="showNotify"
      />
    <div class="vx-card p-6">

      <div class="flex flex-wrap items-center">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ institutionsData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : institutionsData.length }} de {{ institutionsData.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>

              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Buscar..." />
      </div>


      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="institutionsData"
        rowSelection="single"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        :modules="modules">
      </ag-grid-vue>

      <vs-pagination
        :total="totalPages"
        :max="7"
        v-model="currentPage" />

    </div>
  </div>

</template>

<script>
import { AgGridVue } from "@ag-grid-community/vue"
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import ServiceSchoolsSidebar from './sidebar/ServiceSchoolsSidebar.vue'

// Store Module
import moduleServices from '@/store/services/moduleServices.js'
import moduleDataListInstitutions from "@/store/data-list-institutions/moduleDataList.js"
import moduleUserManagement from "@/store/user-management/moduleUserManagement.js"

// Cell Renderer
import CellRendererStatus from "./cell-renderer/CellRendererStatus.vue"
import CellRendererActions from "./cell-renderer/CellRendererActionsServiceSchools.vue"


export default {
  components: {
    AgGridVue,
    vSelect,
    CellRendererStatus,
    CellRendererActions,
    ServiceSchoolsSidebar
  },
  data() {
    return {
      modules: AllCommunityModules,
      searchQuery: "",

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          width: 80,
          filter: false,
        },
        {
          headerName: 'Escuela',
          field: 'name',
          filter: false,
          width: 200,
        },
        {
          headerName: 'Administrador',
          field: 'name_owner',
          filter: false,
        },
        {
          headerName: '% Comisión Total',
          field: 'tiptap_commission',
          filter: false,
          valueFormatter: (params) => this.$options.filters.currencyPercentage(params.data.tiptap_commission, ' %'),
        },
        {
          headerName: '% Comisión a Escuela',
          field: 'percent_main_institution',
          filter: false,
          valueFormatter: (params) => this.$options.filters.currencyPercentage(params.data.percent_main_institution, ' %'),
        },
        {
          headerName: '% Comisión a Acudientes',
          field: 'percent_guardian',
          filter: false,
          valueFormatter: (params) => this.$options.filters.currencyPercentage((params.data.percent_guardian || 0), ' %'),
        },
        {
          headerName: 'Estado',
          field: 'status_digital_payment',
          filter: false,
          cellRendererFramework: 'CellRendererStatus'
        },
        {
          headerName: 'Acciones',
          field: 'transactions',
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            setSidebarData: (data) => {this.sidebarData = data},
            openSidebar: this.openSidebar.bind(this)
          }
        },
      ],

      // Data Sidebar
      openDataSidebar: false,
      sidebarData: {},

      // Cell Renderer Components
      components: {
        CellRendererStatus,
        CellRendererActions
      }
    }
  },
  watch: {
    roleFilter(obj) {
      this.setColumnFilter("role", obj.value)
    },
    statusFilter(obj) {
      this.setColumnFilter("status", obj.value)
    },
    isVerifiedFilter(obj) {
      let val = obj.value === "all" ? "all" : obj.value == "yes" ? "true" : "false"
      this.setColumnFilter("is_verified", val)
    },
    departmentFilter(obj) {
      this.setColumnFilter("department", obj.value)
    },
  },
  computed: {
    institutionsData() {
      if(this.$acl.check('admin')) {
        const inst_services = JSON.parse(JSON.stringify(this.$store.state.servicesModule.institutions_service))
        inst_services.percent_main_institution = 0
        inst_services.percent_guardian = 0

        inst_services.forEach(s => {
          if (s.items && s.items.length > 0) {
            // Getting Client Commission
            const client_percentage = s.items.filter(i => i.id_applicable_entity == 1)
            if (client_percentage && client_percentage.length > 0) {
              s.percent_guardian = client_percentage[0].percent
            }

            // Getting Institution Commission
            const inst_percentage = s.items.filter(i => i.id_applicable_entity == 2)
            if (inst_percentage && inst_percentage.length > 0) {
              s.percent_main_institution = inst_percentage[0].percent
            }
          }

          return s
        })
        
        return inst_services 
      }
             
    },
    paginationPageSize() {
      if(this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages() {
      if(this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get() {
        if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }
  },
  methods: {
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column)
      let modelObj = null

      if(val !== "all") {
        modelObj = { type: "equals", filter: val }
      }

      filter.setModel(modelObj)
      this.gridApi.onFilterChanged()
    },
    resetColFilters() {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null)
      this.gridApi.onFilterChanged()

      // Reset Filter Options
      this.roleFilter = this.statusFilter = this.isVerifiedFilter = this.departmentFilter = { label: 'All', value: 'all' }

      this.$refs.filterCard.removeRefreshAnimation()
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val)
    },
    addNewData() {
      this.$router.push("/users/user-add/").catch((error) => {console.log(error)})
    },
    toggleDataSidebar(val=false) {
      this.openDataSidebar = val
    },
    openSidebar() {
      this.toggleDataSidebar(true);
    },
    showNotify(data) {
      this.$vs.notify({
        color: data.success ? 'success' : 'danger',
        title: data.success ? 'Éxito' : 'Error',
        text: data.msg,
        time: 8000,
        position: 'top-center',
        iconPack: 'feather',
        icon: data.success ? 'icon-check' : 'icon-alert-circle',
      })
      if(data.success)
        this.fetchServiceSchools()
    },
    fetchServiceSchools() {
        this.$store.dispatch("servicesModule/getInstitutionsByService", this.$route.params.serviceId).catch(err => { console.error(err) })
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api
    if(this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(".ag-header-container")
      header.style.left = "-" + String(Number(header.style.transform.slice(11,-3)) + 9) + "px"
    }
  },
  beforeCreate() {
    this.gridOptions = {
      context: {
        componentParent: this
      }
    };
    if(!moduleUserManagement.isRegistered) {
      this.$store.registerModule('userManagement', moduleUserManagement)
      moduleUserManagement.isRegistered = true
    }
    if(!moduleDataListInstitutions.isRegistered) {
      this.$store.registerModule('dataListInstitutions', moduleDataListInstitutions)
      moduleDataListInstitutions.isRegistered = true
    }
    if(!moduleServices.isRegistered) {
      this.$store.registerModule('servicesModule', moduleServices)
      moduleServices.isRegistered = true
    }
  },
  created() {
    if(this.$acl.check('admin')) 
      this.fetchServiceSchools()
  }
}

</script>

<style>
  .ag-header-cell-text {
    overflow: visible !important;
    text-overflow: unset !important;
    white-space: normal !important;
  }
</style>
