<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>Actualizar División de Comisiones</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">

        <!-- Escuela -->
        <vs-input
          disabled="disabled"
          label="Escuela"
          v-model="dataName"
          class="mt-5 w-full"
          name="item-name"
          data-vv-as="Escuela"/>
        <span class="text-danger text-sm" v-show="errors.has('item-name')">{{ errors.first('item-name') }}</span>

        <!-- Comisión Tiptap -->
        <vs-input
          disabled="disabled"
          label="Comisión Tiptap"
          v-model="dataTiptapCommission"
          class="mt-5 w-full"
          name="item-tiptap-commission"
          data-vv-as="Comisión Tiptap"/>
        <span class="text-danger text-sm" v-show="errors.has('item-tiptap-commission')">{{ errors.first('item-tiptap-commission') }}</span>

        <!-- Comisión Escuela -->
        <div class="mt-5 w-full">
          <label class="vs-input--label">Comisión Escuela</label>
          <div class="flex flex-wrap">
            <!-- <p><span>Ingrese el porcentaje de comisión correspondiente a <strong>la escuela</strong></span></p> -->
            <vs-input-number
              @input="checkLength($event, 'dataPercentMainInstitution')"
              label="%"
              v-model="dataPercentMainInstitution"
              :step="0.125"
              :max="dataTiptapCommission"
              name="school-commission"
              class="w-100"
              v-validate="{ regex: /\d+(\.\d+)?$/, min_value: 0, max_value: 100, decimal: true }"
              data-vv-as="Comisión Escuela"
              />
            <span class="text-danger text-sm" v-show="errors.has('school-commission')">{{ errors.first('school-commission') }}</span>
          </div>
        </div>

        <!-- Comisión Acudiente -->
        <!-- <div class="mt-5 w-full">
          <label class="vs-input--label">Comisión Acudiente</label>
          <div class="flex flex-wrap">
            <vs-input-number
              label="%"
              v-model="dataPercentGuardian"
              :step="0.125"
              name="guardian-commission"
              class="w-100"
              v-validate="{ regex: /\d+(\.\d+)?$/, min_value: 0, max_value: 100, decimal: true }"
              />
            <span class="text-danger text-sm" v-show="errors.has('guardian-commission')">{{ errors.first('guardian-commission') }}</span>
          </div>
        </div> -->

        <vs-input
          disabled="disabled"
          label="Comisión Acudiente"
          v-model="dataPercentGuardian"
          class="mt-5 w-full"
          name="item-guardian-commission"
          data-vv-as="Comisión Acudiente"/>
        <span class="text-danger text-sm" v-show="errors.has('item-guardian-commission')">{{ errors.first('item-guardian-commission') }}</span>

        <span class="text-danger text-sm" v-show="!isFormValid">{{ `La sumatoria de Comisión Escuela (${dataPercentMainInstitution} %) y Comisión Acudiente (${dataPercentGuardian} %) debe coincidir con la Comisión Tiptap (${dataTiptapCommission} %)`}}</span>

        <!-- Comisiones - range -->

      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || !valuesUpdated">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    isSidebarActive(val) {
      if(!val) return
      if(Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        let { id, name, tiptap_commission, percent_main_institution, percent_guardian, id_service, id_establishment, id_establishment_type, id_service_types } = JSON.parse(JSON.stringify(this.data))
        this.dataId = id
        this.dataName = name
        this.dataTiptapCommission = tiptap_commission
        this.dataPercentMainInstitution = percent_main_institution
        this.dataPercentGuardian = percent_guardian
        this.dataIdService = id_service
        this.dataIdEstablishment = id_establishment
        this.dataIdEstablishmentType = id_establishment_type
        this.dataIdServiceTypes = id_service_types
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    },
  },
  data() {
    return {
      dataId: null,
      dataName: null,
      dataTiptapCommission: null,
      dataPercentMainInstitution: null,
      // dataPercentGuardian: null,
      dataIdEstablishment: null,
      dataIdEstablishmentType: null,
      dataIdServiceTypes: null,
      maxDecimalLength: 3,

      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if(!val) {
          this.$emit('closeSidebar')
          this.$validator.reset()
          this.initValues()
        }
      },
    },
    dataPercentGuardian: {
      get() {
        return (this.dataTiptapCommission - this.dataPercentMainInstitution).toFixed(3)
      },
      set() {
      }
    },
    isFormValid() {
      const sumCommissions = parseFloat((parseFloat(this.dataPercentMainInstitution) + parseFloat(this.dataPercentGuardian)).toFixed(3))
      return !this.errors.any() && (parseFloat(sumCommissions) === parseFloat(this.dataTiptapCommission))
    },
    valuesUpdated() {
      return this.data.percent_main_institution !== this.dataPercentMainInstitution
    }
  },
  methods: {
    checkLength(value, model) {
      if(value < 0 || value == '')
        value = 0

      if(value > this.dataTiptapCommission)
        value = this.dataTiptapCommission

      this[model] = (typeof value == 'string') ? parseFloat(value).toFixed(3) : value.toFixed(3);
    },
    initValues() {
        this.dataId = null
        this.dataName = null
        this.dataTiptapCommission = null
        this.dataPercentMainInstitution = null
        this.dataPercentGuardian = null
        this.dataIdEstablishment = null
        this.dataIdEstablishmentType = null
        this.dataIdServiceTypes = null
    },
    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          // const obj = {
          //   id_main_institution: this.dataId,
          //   data: [{
          //     id_service: this.dataIdService,
          //     percent_guardian: this.dataPercentGuardian
          //   }]
          // }

          const obj = {
            id_establishment: this.dataIdEstablishment,
            id_establishment_type: this.dataIdEstablishmentType,
            id_service_types: this.dataIdServiceTypes,
            data: [
              {
                code_item: "CSERVICE",
                value: this.dataPercentGuardian
              },
              {
                code_item: "CSERVICE2",
                value: this.dataPercentMainInstitution
              },
            ]
          }

          this.$store.dispatch(`servicesModule/updateInstitutionServices`, obj)
            .then(response => {
              if (response.status)
                this.$emit('showNotify', {success: true, msg: `${this.dataName} actualizado.`})
                this.$emit('closeSidebar')
                this.initValues()
                this.$vs.loading.close()
            })
            .catch(err => {
              this.$emit('showNotify', {success: false, msg: err})
              this.$vs.loading.close()
            })
        }
      })
    },
  },
  components: {
    VuePerfectScrollbar
  },
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
    p {
      margin-bottom: 2rem;
      > span {
        font-size: 0.9rem;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
